export const WATCH_STEP_ONE = "WATCH_STEP_ONE"
export const WATCH_STEP_TWO = "WATCH_STEP_TWO"
export const WATCH_STEP_THREE = "WATCH_STEP_THREE"
export const WATCH_STEP_FOUR = "WATCH_STEP_FOUR"
export const WATCH_STEP_FIVE = "WATCH_STEP_FIVE"
export const WATCH_STEP_SIX = "WATCH_STEP_SIX"
export const WATCH_STEP_PREVIEW = "WATCH_STEP_PREVIEW"

export const CREATE_FORM = "CREATE_FORM"
export const EDIT_FORM = "EDIT_FORM"
