import useSWR from "swr"
import customAxios from "../axios"

export const allMemberShipDetailEndpoint = "/api/memberships/details"
export const userSubscriptionDetailEndpoint = "/api/users/me/memberships"

export async function getMembershipDetails() {
  const endPoint = allMemberShipDetailEndpoint
  const res = await customAxios.get(endPoint)

  return res.data
}

export async function getUserMembershipDetail() {
  const endPoint = "/api/users/me/memberships"
  const res = await customAxios.get(endPoint)

  return res.data
}

export async function manageMembershipPayment(payload) {
  const endPoint = "/api/users/me/memberships"
  const res = await customAxios.post(endPoint, payload)

  return res.data
}

export async function getSubscriptionPlanDiscrepancy(payload) {
  const endPoint = "/api/users/me/memberships/draft_payment"
  const res = await customAxios.put(endPoint, payload)

  return res.data
}

export async function renewUserSubscription(payload) {
  const endPoint = "/api/users/me/memberships/payments"
  const res = customAxios.post(endPoint, payload)

  return res.data
}

export async function cancelSubscriptionDowngrade() {
  const endPoint = "/api/users/me/memberships/cancel_downgrade"
  const res = await customAxios.delete(endPoint)

  return res.data
}

export function useMembershipDetails() {
  const endPoint = allMemberShipDetailEndpoint
  const { data: rawData, error } = useSWR(endPoint, customAxios.get, {
    revalidateOnMount: true,
  })
  const data = rawData?.data

  return [data, error]
}

export function useUserMembershipDetail() {
  const endPoint = "/api/users/me/memberships"
  const {
    data: rawData,
    error,
    mutate,
  } = useSWR(endPoint, customAxios.get, {
    revalidateOnMount: true,
  })
  const data = rawData?.data

  return [data, mutate, error]
}

export function getSubscriptionQuota(userSubDetail) {
  return userSubDetail?.membership?.listingQuota || 0
}
