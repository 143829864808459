import { createSlice } from "@reduxjs/toolkit"

const VISIBLE = "VISIBLE"
const HIDDEN = "HIDDEN"

export const ERROR_DISPLAY = { VISIBLE, HIDDEN }

const initialState = {
  name: "",
  error: "",
  type: "",
  display: ERROR_DISPLAY.HIDDEN,
}

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError(state, { payload }) {
      state.name = payload.name
      state.error = payload.error
      state.type = payload.type
      state.display = ERROR_DISPLAY.VISIBLE
    },
    resetError() {
      return initialState
    },
  },
})

export const { setError, resetError } = errorSlice.actions

export default errorSlice.reducer
