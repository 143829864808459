import customAxios from "@/services/axios"
import { mutate } from "swr"

export const getPaidEndpoint = "/api/users/me/get_paid"
export const loginUserDataEndpoint = "/api/users/me"
export const loginUserProfileEndpoint = "/api/users/me/profile"

export const sendOtp = async function (
  email,
  language = "EN",
  forgotPassword = false
) {
  const endPoint = "/api/otp"
  const res = await customAxios.post(endPoint, {
    email,
    language,
    forgotPassword,
  })

  return res.data
}

export const verifyOtp = async function ({ email, otp }) {
  const endPoint = "/api/otp"
  const res = await customAxios.get(endPoint, {
    params: {
      email,
      otp,
    },
  })

  return res.data
}

export const createUser = async function (payload) {
  const endPoint = "/api/users"
  const res = await customAxios.post(endPoint, payload)

  return res.data
}

export const resetPassword = async function (payload) {
  const endPoint = "/api/forget_pw"
  const res = await customAxios.post(endPoint, payload)

  return res.data
}

export const loginUser = async function (payload) {
  const endPoint = "/api/login"
  const res = await customAxios.post(endPoint, payload)

  return res.data
}

export const updateUserData = async function (payload) {
  const endPoint = loginUserDataEndpoint
  const res = await customAxios.put(endPoint, payload)

  return res.data
}

export const updateUserProfile = async function (payload) {
  const endPoint = loginUserProfileEndpoint
  const res = await customAxios.put(endPoint, payload)

  return res.data
}

export const updateUserSetting = async function (payload) {
  const endPoint = "/api/users/me/settings"
  const res = await customAxios.put(endPoint, payload)

  return res.data
}

export const verifyEmailExist = async function (email) {
  const endPoint = `/api/users/verify_existence/${email}`
  const res = await customAxios.get(endPoint)

  return res.data
}

export const updateShopperPreference = async function (payload) {
  const endPoint = `/api/users/me/shopper_preference`
  const res = await customAxios.put(endPoint, payload)

  return res.data
}

export const getShopperPreference = async function () {
  const endPoint = `/api/users/me/shopper_preference`
  const res = await customAxios.get(endPoint)

  return res.data
}

export const fetchGetPaid = async function () {
  const endPoint = getPaidEndpoint
  const res = await customAxios.get(endPoint)

  return res.data
}

export const updateGetPaid = async function (payload) {
  const endPoint = getPaidEndpoint
  const res = await customAxios.put(endPoint, payload)

  return res.data
}

export const updateBankInfo = async function (payload) {
  const endPoint = `/api/users/me/bank_info`
  const res = await customAxios.post(endPoint, payload)

  return res.data
}

export const updateIdDocument = async function (payload) {
  const endPoint = `/api/users/me/id_documents`
  const res = await customAxios.post(endPoint, payload)

  return res.data
}

export async function verifyOAuth({ oauthAgent, oauthUserId }) {
  const endPoint = `/api/users/verify_oauth`
  const payload = { oauthAgent, oauthUserId }
  const res = await customAxios.put(endPoint, payload)

  return res.data
}

export const mutateLoginUser = () =>
  mutate([loginUserDataEndpoint, loginUserProfileEndpoint])
