import "../script/wdyr"
import "react-toastify/dist/ReactToastify.css"
import "react-datepicker/dist/react-datepicker.css"
import "../styles/globals.css"
import "swiper/css"
import { useState, useEffect, Fragment } from "react"
import { useRouter } from "next/router"
import Head from "next/head"
import useSWR, { SWRConfig } from "swr"
import { Provider, useDispatch, useSelector } from "react-redux"
import { ToastContainer, Slide } from "react-toastify"
import createsStore from "@/store/index"
import {
  updateLoginData,
  updateGetPaid,
  updateAllMembershipDetails,
  updateUserSubscriptionDetail,
  logout,
  updateRating,
} from "@/store/user"
import { updateKDollarRates } from "@/store/content"
import { fetcher } from "@/services/axios"
import { appWithTranslation } from "next-i18next"
import { RedirectToBasicProfile } from "@/hooks/useBasicProfile"
import { PreferenceProvider } from "@/hooks/useUserPeference"
import Debug from "debug"
import BaiduTracking from "@/components/Meta/BaiduTracking"
import Loading from "@/components/Layout/Loading"
import { getSellerRating } from "@/services/rating"
import { GoogleOauthProvider } from "@/components/SocialMedia/Google"
import { getPaidEndpoint } from "@/services/authentication"
import {
  allMemberShipDetailEndpoint,
  userSubscriptionDetailEndpoint,
} from "@/services/subscription"
import { kDollarRateEndpoint, convertKDollarData } from "@/services/kdollar"

const debug = Debug("web:app")

const GetUser = () => {
  const [mounted, setMounted] = useState(false)
  const dispatch = useDispatch()
  const requests = ["/api/users/me", "/api/users/me/profile"]
  const request = mounted ? requests : null
  useSWR(
    request,
    (...requests) => Promise.all(requests.map((uri) => fetcher(uri, "get"))),
    {
      onSuccess: (data) => {
        const [loginData, userProfile] = data
        debug("login", loginData)
        dispatch(updateLoginData({ loginData, userProfile }))
      },
      onErrorRetry: (error) => {
        if (error.response.status === 401) {
          dispatch(logout())
        }
      },
    }
  )
  const loginUserId = useSelector((state) => state.user.loginData?.id)

  useSWR(mounted ? getPaidEndpoint : null, fetcher, {
    onSuccess: (data) => {
      debug(data)
      dispatch(updateGetPaid(data))
    },
  })

  useSWR(mounted ? allMemberShipDetailEndpoint : null, fetcher, {
    onSuccess: (data) => {
      debug(data)
      dispatch(updateAllMembershipDetails(data))
    },
  })

  useSWR(mounted ? userSubscriptionDetailEndpoint : null, fetcher, {
    onSuccess: (data) => {
      debug(data)
      dispatch(updateUserSubscriptionDetail(data))
    },
  })

  useSWR(mounted ? kDollarRateEndpoint : null, fetcher, {
    onSuccess: (rawData) => {
      debug(rawData)
      dispatch(updateKDollarRates(convertKDollarData(rawData)))
    },
  })

  useEffect(() => {
    async function getRating() {
      if (loginUserId) {
        try {
          const res = await getSellerRating(loginUserId)
          dispatch(
            updateRating({ ...res, hasRating: Boolean(res?.results?.length) })
          )
        } catch (error) {
          console.error(error)
        }
      }
    }
    getRating()
  }, [loginUserId])

  useEffect(() => {
    setMounted(true)
  }, [])
  return <></>
}

const HttpsRedirect = () => {
  if (
    process.env.NODE_ENV !== "development" &&
    typeof window !== "undefined" &&
    window.location &&
    window.location.protocol === "http:"
  ) {
    window.location.href = window.location.href.replace(/^http(?!s)/, "https")
    return null
  }
  return <div></div>
}

const env = process.env.NEXT_PUBLIC_ENV

function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()
  const { preloadedState, userSetting } = pageProps
  const store = createsStore(preloadedState)
  debug(preloadedState)

  const skipIndex = env != "prod" || router.pathname.startsWith("/draft")

  const globalSwrConfig = {
    revalidateOnFocus: false,
    onErrorRetry: (error) => {
      debug(error)
      if (error.response.status === 401) {
        return
      }
    },
  }

  return (
    <Fragment>
      <Head>
        <title>KRONOS8</title>
        <link rel="icon" href="/favicon.ico" />
        {skipIndex && <meta name="robots" content="noindex" />}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
          key="resize"
        />
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_DOMAIN}/${router.locale}${router.asPath}`}
        />
        <meta
          key="og:image"
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_DOMAIN}/image/square-logo.png`}
        />
        <meta
          property="fb:app_id"
          content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
        />
      </Head>
      <BaiduTracking />

      <Provider store={store}>
        <PreferenceProvider defaultValue={userSetting}>
          <GoogleOauthProvider>
            <SWRConfig value={globalSwrConfig}>
              <GetUser />
              <HttpsRedirect />
              <RedirectToBasicProfile router={router}>
                {getLayout(<Component {...pageProps} />)}
              </RedirectToBasicProfile>
              <Loading />
              <ToastContainer
                className="tablet:bottom-4 tablet:right-4 tablet:left-auto tablet:translate-x-0"
                position="bottom-center"
                transition={Slide}
                closeButton={false}
                autoClose={3500}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                theme="dark"
                icon={false}
                toastClassName="min-h-[50px]"
              />
            </SWRConfig>
          </GoogleOauthProvider>
        </PreferenceProvider>
      </Provider>
    </Fragment>
  )
}

export default appWithTranslation(MyApp)
