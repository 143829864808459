import { DateTime } from "luxon"
import { compose } from "@reduxjs/toolkit"

export function isBrowser() {
  return typeof window !== "undefined"
}

export function id(x) {
  return x
}

export function startsWithZero(str) {
  const leadingZeroRegex = /^[0]\d*$/
  const hasLeadingZero = leadingZeroRegex.test(str)

  return hasLeadingZero
}

export function filterDecimal(setValue) {
  return function (e) {
    const { name, value } = e.target
    const filteredValue = value.replace(/\./g, "")

    if (startsWithZero(value)) {
      setValue(name, value.replace(/0/, ""))
      return
    }

    setValue(name, filteredValue)
  }
}

export function notDisabled() {
  return false
}

export const getStart = (array) => (array?.length ? array[0] : null)

export const getLast = (array) => array[array.length - 1]

export const getImageNameFromUrl = (str) => {
  if (!str) {
    return ""
  }

  const urlArray = str.split("/")
  const imageName = getLast(urlArray)

  return imageName
}

export function base64Encode(val) {
  try {
    const str = typeof val == "number" ? val.toString() : val
    return typeof btoa === "undefined"
      ? new Buffer(str).toString("base64")
      : btoa(val)
  } catch (error) {
    console.error(error)
    return val
  }
}

export function base64Decode(val) {
  try {
    if (typeof atob === "undefined") {
      if (Buffer.from(val, "base64").toString("base64") !== val) return val
      return new Buffer(val, "base64").toString()
    } else {
      return atob(val)
    }
  } catch (error) {
    console.error(error)
    return val
  }
}

export function isBefore(baseDate) {
  return function (date) {
    return DateTime.fromISO(baseDate) >= DateTime.fromISO(date)
  }
}

export function didStripePaymentFail(res) {
  const errorTypes = [
    "api_error",
    "card_error",
    "idempotency_error",
    "invalid_request_error",
  ]

  return (
    errorTypes.includes(res?.error?.type) ||
    res?.error?.payment_intent.last_payment_error
  )
}

export function stripDecimal(num) {
  const isValidNumber = typeof num === "number" && !Number.isNaN(num)

  if (!isValidNumber) {
    return 0
  }
  const isFractional = num % 1 !== 0

  return isFractional ? num : Math.trunc(num)
}

export const removeDecimal = compose(stripDecimal, Number)

export function getCensoredCard(card) {
  return `****${card}`
}

export function addTwoWeeks(date) {
  return DateTime.fromISO(date).plus({ days: 14 })
}

export const parseAnInt = (radix) => (value) => parseInt(value, radix)

export const parseIntDecimal = parseAnInt(10)

export function roundNumber(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export const pullToFirst = (filterFunction) => (list) => {
  const rest = list.filter((item) => !filterFunction(item))
  const first = list.filter(filterFunction)

  return [...first, ...rest]
}
