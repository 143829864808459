import axios from "axios"
import { KEYS, local } from "../utils/storage"

const customAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_DOMAIN,
})
const requestHandler = (request) => {
  const accessToken =
    typeof window !== "undefined" ? local.get(KEYS.ACCESS_TOKEN) : null
  accessToken
    ? (request.headers["Authorization"] = `Bearer ${accessToken}`)
    : ""
  return request
}
const errorHandler = (error) => Promise.reject(error)

customAxios.interceptors.request.use(requestHandler, errorHandler)

export const fetcher = (url, method, option) =>
  customAxios({ url, method, ...option }).then((res) => res.data)
export const fetchWithQuery = (url, queryObj) => {
  const queryParams = new URLSearchParams(queryObj).toString()
  return fetcher(`${url}?${queryParams}`)
}
export default customAxios
