import { createSlice } from "@reduxjs/toolkit"
import { CREATE_FORM, EDIT_FORM } from "@/components/Seller/constants"
import {
  initialState as initStep,
  stepTransitions,
} from "@/components/Seller/stepReducer"

const initialState = {
  data: {
    WATCH_STEP_ONE: { status: "clean" },
    WATCH_STEP_TWO: { status: "clean" },
    WATCH_STEP_THREE: { status: "clean" },
    WATCH_STEP_FOUR: { status: "clean" },
    WATCH_STEP_FIVE: { status: "clean" },
    WATCH_STEP_SIX: { status: "clean" },
  },
  step: initStep,
  formStatus: CREATE_FORM,
  flaggedFields: [],
  watchId: null,
  rawData: {},
}

const watchFormSlice = createSlice({
  name: "watchForm",
  initialState,
  reducers: {
    submitStep(_, { payload }) {
      return {
        ...initialState,
        data: {
          ...initialState.data,
          [payload.step]: {
            ...payload.value,
            status: "entered",
          },
        },
        step: {
          ...initialState.step,
          currentStep: stepTransitions[payload.step].next,
        },
      }
    },
    setSellWatchForm(state, { payload }) {
      state.data = payload
      state.flaggedFields = []
      state.step = initStep
      state.formStatus = CREATE_FORM
      state.watchId = null
      state.rawData = {}
    },
    setModifyWatchForm(state, { payload }) {
      state.data = payload.data
      state.flaggedFields = payload.flaggedFields
      state.step = initStep
      state.formStatus = EDIT_FORM
      state.watchId = payload.id
      state.rawData = payload.rawData
    },
    resetStep() {
      return initialState
    },
  },
})

export const { submitStep, resetStep, setSellWatchForm, setModifyWatchForm } =
  watchFormSlice.actions

export default watchFormSlice.reducer
