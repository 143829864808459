import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { sendNotification } from "@/services/utils"
import { useRouter } from "next/router"
import Debug from "debug"
import { cookie, KEYS, local } from "@/services/utils/storage"
import { useMultilang } from "./useMultilang"

const debug = Debug("web:hook:useUserPreference")

export const mapper = {
  EN: "en",
  SC: "zh-CN",
  TC: "zh-HK",
}

export function getPreference(lang = "EN") {
  return (
    local.get(KEYS.USER_SETTING) || {
      language: lang,
      currency: "HKD",
      shippingRegion: "Hong Kong",
    }
  )
}

function setStorage(data) {
  const payload = {
    currency: data.currency,
    language: data.language,
    shippingRegion: data.shippingRegion,
  }
  local.set(KEYS.USER_SETTING, payload)
  cookie.set(KEYS.USER_SETTING, payload) // for server redirect by lang
}

const initPreference = {
  preference: getPreference(),
  setPreference: () => {},
}

export const PreferenceContext = createContext(initPreference)

function changeLocalePath(router, locale) {
  const { asPath, defaultLocale } = router
  const localePrefix = locale === defaultLocale ? "" : `/${locale}`

  window.location.href = `${window.location.origin}${localePrefix}${asPath}`
}

export const PreferenceProvider = ({ children, defaultValue = null }) => {
  const { t } = useMultilang()
  const router = useRouter()
  const [preference, setPreference] = useState(defaultValue || getPreference())

  function updateRouteLocale(lang) {
    const locale = mapper[lang]
    if (router.pathname.match(/\/draft\//)) return
    if (locale && locale !== router.locale) {
      // router.push({ pathname, query }, asPath, { locale: locale })
      // force reload page for update stripe locale
      changeLocalePath(router, locale)
    }
  }

  const contextValue = useMemo(
    () => ({
      preference,
      setPreference: (data) => {
        setStorage(data)
        setPreference(data)
      },
    }),
    [preference]
  )
  useEffect(() => {
    const updated = local.get(KEYS.UPDATED_LANGUAGE)
    if (updated) {
      sendNotification(t("notify.preference-updated"))
      local.remove(KEYS.UPDATED_LANGUAGE)
    }
  }, [])

  useEffect(() => {
    debug("redirect", preference)
    updateRouteLocale(preference.language)
  }, [preference])

  return (
    <PreferenceContext.Provider value={contextValue}>
      {children}
    </PreferenceContext.Provider>
  )
}

const useUserPreference = () => {
  const { t } = useMultilang()
  const { preference, setPreference } = useContext(PreferenceContext)

  const onSubmit = async (data) => {
    if (data.language == preference.language) {
      sendNotification(t("notify.preference-updated"))
    } else {
      local.set(KEYS.UPDATED_LANGUAGE, true) // prompt toast after page reload
    }
    setPreference(data)
  }

  const displayLang = useMemo(() => preference.language, [preference])
  const displayLangTranslated = useMemo(() => {
    if (displayLang == "TC") return "繁"
    if (displayLang == "SC") return "简"
    return "EN"
  }, [displayLang])

  return {
    preference,
    onSubmit,
    displayLang,
    displayLangTranslated,
  }
}

export default useUserPreference
