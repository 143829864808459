import Cookies from "js-cookie"

export const KEYS = {
  USER_SETTING: "user_setting",
  ACCESS_TOKEN: "access_token",
  COOKIES_CONSENT: "cookies_consent",
  UPDATED_LANGUAGE: "updated_language",
  CLOSE_SMARTBANNER: "close_smartbanner",
}

export const JSON_STORAGE = [KEYS.USER_SETTING]

function isServer() {
  return typeof window === "undefined"
}

function isCookieDisabled() {
  return !navigator.cookieEnabled
}

function promptAlert() {
  let hasDisplayedAlert = false

  return function () {
    if (!hasDisplayedAlert) {
      hasDisplayedAlert = true
      window.alert(
        "To continue visting KRONOS8, please enable cookies in this browser\n如要繼續訪問 KRONOS8，請開啟此瀏覽器的 Cookie\n如要继续访问 KRONOS8，请开启此浏览器的 Cookie"
      )
      return
    }
  }
}

const displayCookieAlert = promptAlert()

export const local = {
  get: (key, defaultValue) => {
    if (isServer()) {
      return defaultValue
    }

    if (isCookieDisabled()) {
      displayCookieAlert()
      return
    }

    const result = localStorage.getItem(key)
    if (typeof result === "undefined") return defaultValue
    if (JSON_STORAGE.indexOf(key) !== -1) {
      return JSON.parse(result)
    }

    return result
  },

  set: (key, value) => {
    if (isServer() || isCookieDisabled()) {
      return
    }

    let payload = value
    if (JSON_STORAGE.indexOf(key) !== -1) {
      payload = JSON.stringify(value)
    }
    localStorage.setItem(key, payload)
  },

  remove: (key) => {
    if (isServer() || isCookieDisabled()) {
      return
    }

    localStorage.removeItem(key)
  },
}

export const cookie = {
  get: (key, defaultValue) => {
    if (isServer()) {
      return defaultValue
    }

    if (isCookieDisabled()) {
      displayCookieAlert()
      return
    }

    const result = Cookies.get(key)
    if (typeof result === "undefined") return defaultValue
    if (JSON_STORAGE.indexOf(key) !== -1) {
      return JSON.parse(result)
    }

    return result
  },

  set: (key, value) => {
    if (isServer() || isCookieDisabled()) {
      return
    }

    let payload = value
    if (JSON_STORAGE.indexOf(key) !== -1) {
      payload = JSON.stringify(value)
    }
    Cookies.set(key, payload)
  },

  remove: (key) => {
    if (isServer() || isCookieDisabled()) {
      return
    }

    Cookies.remove(key)
  },
}
