import { LazyLoadImage } from "react-lazy-load-image-component"

function UploadedImage({ src, ...rest }) {
  let srcUrl = src
  if (src && src.match("aliyuncs") && src.match(/\.tiff$/)) {
    srcUrl = `${src}?x-oss-process=image/format,jpg`
  }
  return <LazyLoadImage src={srcUrl} alt="" {...rest} />
}

export { UploadedImage, LazyLoadImage }
