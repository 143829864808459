const Icon = function ({ children, onClick }) {
  return (
    <button
      className="border border-gray-700 tablet:rounded-full w-[30px] h-[30px] flex justify-center items-center mr-[15px]"
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  )
}

export default Icon

export function IconImg(props) {
  const { className, ...rest } = props
  return <img {...rest} className="w-3.5 h-3.5" />
}
