import { KDOLLAR_TYPES } from "@/components/Profile/KDollar/utils/constants"

export const userKDollarHistoryEndpoint = "/api/users/me/kdollar/history"
export const kDollarRateEndpoint = "/api/kdollar/rates"

export const convertKDollarData = (data) => {
  const result = { USD: {}, HKD: {} }
  const typesWithCurrency = [KDOLLAR_TYPES.EARN, KDOLLAR_TYPES.REDEEM]

  data?.forEach((item) => {
    if (typesWithCurrency.includes(item?.type) && result?.[item?.currency]) {
      result[item?.currency][item?.type] = item
    } else {
      result[item?.type] = item
    }
  })

  return result
}
