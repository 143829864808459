import { useSelector } from "react-redux"
import { useRouter } from "next/router"
import { isBrowser } from "@/services/utils/common"
import * as ROUTES from "@/constants/route"

export const useHasEmptyProfile = () => {
  const loginData = useSelector((state) => state.user.loginData)
  const userProfile = useSelector((state) => state.user.userProfile)
  const isMerchant = useSelector((state) => state.user.isMerchant)
  const { displayName } = loginData || {}
  const { firstName, lastName, birthMonth, birthYear } = userProfile || {}
  const profileFields = [
    displayName,
    firstName,
    lastName,
    birthMonth,
    birthYear,
  ]
  const hasEmptyProfile = !profileFields.every(Boolean)

  if (isMerchant) {
    return false
  }

  return hasEmptyProfile
}

export const RedirectToBasicProfile = ({ router, children }) => {
  const isLogin = useSelector((state) => state.user.isLogin)
  const isNotMerchant = useSelector((state) => !state.user.isMerchant)
  const isNotSkipRoute = ![ROUTES.SIGNUP, ROUTES.SIGNUP_PROFILE].includes(
    router.route
  )
  const hasEmptyProfile = useHasEmptyProfile()
  const shouldRedirect = [
    isBrowser(),
    isLogin,
    hasEmptyProfile,
    isNotSkipRoute,
    isNotMerchant,
  ].every(Boolean)

  if (shouldRedirect) {
    router.push(ROUTES.SIGNUP_PROFILE)
  }

  return children
}

export const useHasProfile = (route = ROUTES.HOME) => {
  const router = useRouter()
  const profileFilled = !useHasEmptyProfile()

  if (profileFilled) {
    router.push(route)
  }
}
