export const HOME = "/"

export const LOGIN = "/login"

export const PROFILE = "/profile"
export const PROFILE_ACCOUNT = "/profile/account"
export const PROFILE_CHANGE_PASSWORD = "/profile/change-password"
export const PROFILE_GET_PAID = "/profile/get-paid"
export const PROFILE_PAYMENT_METHOD = "/profile/billings"
export const PROFILE_PREFERENCES = "/profile/preferences"
export const PROFILE_LISTINGS = "/profile/listings"
export const PROFILE_BUYER_ORDERS = "/profile/buyer-orders"
export const PROFILE_SELLER_ORDERS = "/profile/seller-orders"
export const PROFILE_TEAM = "/profile/team"
export const PROFILE_BUYER_OFFERS = "/profile/buyer-offers"
export const PROFILE_SELLER_OFFERS = "/profile/seller-offers"
export const PROFILE_RATINGS = "/profile/ratings"
export const PROFILE_SUBSCRIPTION = "/profile/subscription"
export const PROFILE_SUBSCRIPTION_SUCCESS = "/profile/subscription/success"
export const PROFILE_SUBSCRIPTION_FAIL = "/profile/subscription/fail"
export const PROFILE_K_DOLLARS = "/profile/k-dollars"

export const RECOVERY = "/recovery"
export const RECOVERY_SUCCESS = "/recovery/success"

export const SELL_WATCH_HOME = "/sell"
export const SELL_WATCH_FORM = "/sell/watch"
export const SELL_WATCH_MODIFY = "/sell/modify"
export const SELL_WATCH_RESUBMIT = "/sell/resubmit"

export const BUY_WATCH = "/watch"
export const BUY_WATCH_FAIL = "/profile/buyer-orders/fail"

export const SIGNUP = "/signup"
export const SIGNUP_PROFILE = "/signup/profile"

export const AUTHENTICATION_SERVICE_DETAILS = "/authentication-service"

export const PROFILE_ROUTES = [
  PROFILE,
  PROFILE_ACCOUNT,
  PROFILE_CHANGE_PASSWORD,
  PROFILE_GET_PAID,
  PROFILE_PAYMENT_METHOD,
  PROFILE_PREFERENCES,
  PROFILE_LISTINGS,
  PROFILE_BUYER_ORDERS,
  PROFILE_SELLER_ORDERS,
  PROFILE_TEAM,
  PROFILE_BUYER_OFFERS,
  PROFILE_SELLER_OFFERS,
  PROFILE_RATINGS,
  PROFILE_SUBSCRIPTION,
  PROFILE_K_DOLLARS,
]

export const ARTICLES = "/articles"
