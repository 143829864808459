import { createSlice } from "@reduxjs/toolkit"

const contentSlice = createSlice({
  name: "content",
  initialState: {
    loading: false,
    slogans: [],
    exchangeRate: {
      USD: 7.8,
    },
    kDollarRate: null,
  },
  reducers: {
    updateSlogans: (state, { payload }) => {
      state.slogans = payload.slogans
    },
    updateExchangeRate: (state, { payload }) => {
      state.exchangeRate = payload.exchangeRate
    },
    updateLoading: (state, { payload }) => {
      state.loading = payload.loading
    },
    updateKDollarRates: (state, { payload }) => {
      state.kDollarRates = payload
    },
  },
})

// action creators
export const {
  updateSlogans,
  updateExchangeRate,
  updateLoading,
  updateKDollarRates,
} = contentSlice.actions
// reducer
export default contentSlice.reducer
