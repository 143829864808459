const ErrorToast = ({ message }) => {
  return (
    <div className="flex items-center font-bold text-sm text-white">
      <div className="ml-1.5">
        <img src="/icon/cancel-white.svg" alt="" width={10} height={10} />
      </div>

      <p className="ml-4">{message}</p>
    </div>
  )
}

export default ErrorToast
