export const KDOLLAR_TYPES = {
  REFERRER: "REFERRER",
  REFEREE: "REFEREE",
  EARN: "EARN",
  REDEEM: "REDEEM",
  SELL: "SELL",
  BUY_LAYER_1: "BUY_LAYER_1",
  BUY_LAYER_2: "BUY_LAYER_2",
  BUY_LAYER_3: "BUY_LAYER_1",
}

export const K_DOLLAR_ACTION = {
  ADD: "ADD",
  DEDUCT: "DEDUCT",
}

export const K_DOLLAR_TYPE = {
  REFERRAL: "REFERRAL",
  ORDER: "ORDER",
  K8_OPERATION: "K8_OPERATION",
  EXPIRY: "EXPIRY",
}

export const K_DOLLAR_ACTIVITY = {
  REFERRER: "REFERRER",
  REFEREE: "REFEREE",
  SELL: "SELL",
  BUY_LAYER_1: "BUY_LAYER_1",
  BUY_LAYER_2: "BUY_LAYER_2",
  BUY_LAYER_3: "BUY_LAYER_3",
  REDEEM: "REDEEM",
  REFUND: "REFUND",
  K8_REWARD: "K8_REWARD",
  K8_RECALL: "K8_RECALL",
  SYSTEM_EXPIRY: "SYSTEM_EXPIRY",
}

export const K_DOLLAR_ACTIVITY_TRANS = {
  [K_DOLLAR_ACTIVITY.REFERRER]: "kdollar.activity.referrer",
  [K_DOLLAR_ACTIVITY.REFEREE]: "kdollar.activity.referee",
  [K_DOLLAR_ACTIVITY.SELL]: "kdollar.activity.sell",
  [K_DOLLAR_ACTIVITY.BUY_LAYER_1]: "kdollar.activity.buy-layer-1",
  [K_DOLLAR_ACTIVITY.BUY_LAYER_2]: "kdollar.activity.buy-layer-2",
  [K_DOLLAR_ACTIVITY.BUY_LAYER_3]: "kdollar.activity.buy-layer-3",
  [K_DOLLAR_ACTIVITY.REDEEM]: "kdollar.activity.redeem",
  [K_DOLLAR_ACTIVITY.REFUND]: "kdollar.activity.refund",
  [K_DOLLAR_ACTIVITY.K8_REWARD]: "kdollar.activity.k8-reward",
  [K_DOLLAR_ACTIVITY.K8_RECALL]: "kdollar.activity.k8-recall",
  [K_DOLLAR_ACTIVITY.SYSTEM_EXPIRY]: "kdollar.activity.k8-system-expiry",
}
