import { toast } from "react-toastify"
import { ErrorToast, SuccessToast } from "@/components/common/Toast"
import frontendError from "@/assets/json/frontend_error"
import { base64Encode } from "./common"

export const classNames = (...classes) => classes.filter(Boolean).join(" ")
// FIXME 02/15
// improve me

export const getYears = (yearNow, backTrack = 100) => {
  const years = [...Array(backTrack).keys()].map((deficit) => yearNow - deficit)
  return years
}

export const checkboxEnum = (value = false, checkedValue = null) => {
  return value === checkedValue ? true : false
}
export const createOauthPayload = (oauthAgent) => (oauthUserId) => ({
  oauthAgent,
  oauthUserId,
})

export const sortByKey = (array, key, orderBy = "asc") => {
  const myArray = array.slice()
  const orderMap = {
    asc: (a, b) => a[key].localeCompare(b[key], "en", { caseFirst: "upper" }),
    desc: (a, b) => b[key].localeCompare(a[key], "en", { caseFirst: "upper" }),
  }
  myArray.sort(orderMap[orderBy] || orderMap.asc)

  return myArray
}

export const getConditionUrl = (condition) => {
  const url = "/icon/condition/"
  const conditionObj = {
    Unworn: "unworn.svg",
    "Very good": "very-good.svg",
    Good: "good.svg",
    Fair: "fair.svg",
    Poor: "poor.svg",
    Incomplete: "incomplete.svg",
  }

  return conditionObj[condition]
    ? url + conditionObj[condition]
    : "/icon/condition/poor.svg"
}

export const getDialColorUrl = (dialColor) => {
  const url = "/icon/dial-color/"
  const dialColorObj = {
    Black: "black@2x.png",
    Blue: "blue@2x.png",
    Bordeaux: "bordeaux@2x.png",
    Bronze: "bronze@2x.png",
    Brown: "brown@2x.png",
    Champagne: "champagne@2x.png",
    "Gold (solid)": "gold-solid@2x.png",
    Gold: "gold@2x.png",
    Green: "green@2x.png",
    Grey: "grey@2x.png",
    "Mother of pearl": "mother-of-pearl@2x.png",
    Orange: "orange@2x.png",
    Pink: "pink@2x.png",
    Purple: "purple@2x.png",
    Red: "red@2x.png",
    "Silver (solid)": "silver-solid@2x.png",
    Silver: "silver@2x.png",
    Transparent: "transparent@2x.png",
    White: "white@2x.png",
    Yellow: "yellow@2x.png",
  }
  return dialColorObj[dialColor]
    ? url + dialColorObj[dialColor]
    : "/icon/dial-color/black@2x.png"
}

export const getCaseMaterialUrl = (caseMaterial) => {
  const url = "/icon/case-material/"
  const caseMaterialObj = {
    Aluminium: "aluminum@2x.png",
    Bronze: "bronze@2x.png",
    Carbon: "carbon@2x.png",
    Ceramic: "ceramic@2x.png",
    "Gold / Steel": "gold-steel@2x.png",
    Palladium: "palladium@2x.png",
    Plastic: "plastic@2x.png",
    Platinum: "platinum@2x.png",
    "Red gold": "red-gold@2x.png",
    "Rose gold": "rose-gold@2x.png",
    Silver: "silver@2x.png",
    Steel: "steel@2x.png",
    Tantalum: "tantalum@2x.png",
    Titanium: "titanium@2x.png",
    Tungsten: "tungsten@2x.png",
    "White gold": "white-gold@2x.png",
    "Yellow gold": "yellow-gold@2x.png",
  }
  return caseMaterialObj[caseMaterial]
    ? url + caseMaterialObj[caseMaterial]
    : "/icon/case-material/aluminum@2x.png"
}

export const capitalize = (str) => {
  const start = str.slice(0, 1).toUpperCase()
  const rest = str.slice(1)
  return `${start}${rest}`
}

const stripStatus = ({ status, ...rest }) => rest
const constructResources = (arr) => arr.filter(Boolean).map((uri) => ({ uri }))
const filterEmptyValue = (object) =>
  Object.entries(object)
    .filter(([, value]) => Boolean(value))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    )

export const normalizeWatchData = (data) => {
  if (!Object.keys(data).length) {
    return {}
  }
  const [
    stepOneData,
    stepTwoData,
    stepThreeData,
    stepFourData,
    stepFiveData,
    stepSixData,
  ] = Object.values(data).map(stripStatus)

  const { brand, model, refNo, ...restOfStepOne } = stepOneData
  const { prodYear, authenticationServiceTnC, ...restOfStepTwo } = stepTwoData
  const {
    caseOthers,
    caliberOthers,
    dialFeature,
    handDetail,
    functions,
    ...restOfStepFour
  } = stepFourData
  const filteredStepFour = filterEmptyValue(restOfStepFour)

  const optionalFields = {
    ...filteredStepFour,
    ...(caseOthers ? { caseOthers: caseOthers.join(", ") } : {}),
    ...(caliberOthers ? { caliberOthers: caliberOthers.join(", ") } : {}),
    ...(dialFeature ? { dialFeature: dialFeature.join(", ") } : {}),
    ...(handDetail ? { handDetail: handDetail.join(", ") } : {}),
    ...(functions ? { functions: functions.join(", ") } : {}),
  }

  const hasOptionalField = Object.values(optionalFields).some(Boolean)
  const resources = constructResources(Object.values(stepFiveData))

  return {
    brandId: brand?.select?.id || null,
    modelId: model?.select?.id || null,
    refNoId: refNo?.select?.id || null,
    customInfoBrand: brand?.input || null,
    customInfoModel: model?.input || null,
    customInfoRefNo: refNo?.input || null,
    prodYear: prodYear?.input || (prodYear?.checkbox === "unknown" && null),
    ...restOfStepOne,
    ...restOfStepTwo,
    ...stepThreeData,
    resources,
    ...(hasOptionalField ? optionalFields : {}),
    verify: stepSixData,
  }
}

export const getDescendantProp = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj)
}

const generateRandomString = (length) => {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  const chars = [...Array(length).fill("")].map(() => {
    const randomChar = Math.floor(Math.random() * charactersLength)
    return characters.charAt(randomChar)
  })
  const result = chars.join("")

  return result
}

export const createFileKey = () => {
  const now = new Date()
  const year = now.getFullYear()
  const month = now.getMonth()
  const day = now.getDate()
  const hours = now.getHours()
  const min = now.getMinutes()
  const sec = now.getSeconds()
  const randomString = generateRandomString(16)

  return `${year}${month}${day}${hours}${min}${sec}${randomString}`
}

export const getFileSuffix = (filename) => {
  const pos = filename.lastIndexOf(".")
  const suffix = pos !== -1 ? filename.substring(pos) : ""

  return suffix
}

export const getUploadPayload = (presignedData, file, filename) => {
  const formData = new FormData()
  const fileSuffix = getFileSuffix(file.name)
  const randomKey = createFileKey()
  const uploadFilePath = `${presignedData.dir}${
    filename || randomKey
  }${fileSuffix}`

  const uploadParams = {
    key: uploadFilePath,
    policy: presignedData.policy,
    OSSAccessKeyId: presignedData.accessid,
    success_action_status: "200",
    callback: presignedData.callback,
    signature: presignedData.signature,
    file,
  }

  for (const key in uploadParams) {
    formData.append(key, uploadParams[key])
  }

  return formData
}

export const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn && fn(...args))

export const sendNotification = (
  message = "",
  type = "success",
  options = {}
) => {
  if (type === "success") {
    return toast.success(<SuccessToast message={message} />, options)
  } else {
    return toast.error(<ErrorToast message={message} />, options)
  }
}

export const trimString = (limit) => (str) => {
  const padding = "..."
  if (!str) return ""
  const strLength = str.length
  const exceedLimit = strLength > limit
  const end = exceedLimit ? limit : strLength
  const truncated = exceedLimit
    ? str.slice(0, end).concat(padding)
    : str.slice(0, end)
  return truncated
}

export const sum = (a, b) => a + b

export const handleApiError = (t) => (error) => {
  const errorKey =
    error?.response?.data?.customCode || error?.response?.data?.message
  const fallbackMessage = t("error-msg.error-occured")
  const localizedMessage = frontendError[errorKey]
    ? t(frontendError[errorKey])
    : fallbackMessage

  console.error(error)
  sendNotification(localizedMessage, "error")
}

export const isEven = (n) => n % 2 === 0

/**
 * returns corresponding number in sequence 0110
 * - eg: alternate(0) = 0
 * - eg: alternate(1) = 1
 * - eg: alternate(2) = 1
 * - eg: alternate(3) = 0
 * @param {number} n number
 * @returns number
 */
export const alternate = (n) => {
  const floored = n % 4

  return Math.abs(2 * floored - 3) % 3
}

export const isAlternate = (n) => alternate(n) === 0

export function reorder(sortMethod) {
  return function reorderArr(arr) {
    return arr.sort(sortMethod)
  }
}

export function getSellerPageUrl(id) {
  return `/seller/${base64Encode(id)}`
}

export function getUserName(user) {
  return user?.businessName || user?.displayName
}

function byLowerBound(recordA, recordB) {
  return recordA.lowerBound - recordB.lowerBound
}

export const reorderCommissionRates = reorder(byLowerBound)
