import { KEYS, local } from "@/services/utils/storage"
import { createSlice } from "@reduxjs/toolkit"
import { MERCHANT_OWNER, MERCHANT_STAFF } from "@/constants/merchant"

const MERCHANT_ROLES = [MERCHANT_OWNER, MERCHANT_STAFF]

export const initLoginData = {
  displayName: "",
  referralCode: "",
  user_setting: {
    language: "EN",
    currency: "HKD",
    shippingRegion: "Hong Kong",
  },
}

const userSlice = createSlice({
  name: "user",
  initialState: {
    userProfile: null,
    loginData: initLoginData,
    isLogin: null,
    isMerchant: false,
    getPaidData: null,
    userRating: {},
    allMembershipDetails: null,
    userSubscriptionDetail: null,
  },
  reducers: {
    updateLoginData: (state, { payload }) => {
      const { role } = payload.loginData
      state.loginData = payload.loginData
      state.userProfile = payload.userProfile
      state.isLogin = true
      state.isMerchant = MERCHANT_ROLES.includes(role)
    },
    updateSetting: (state, { payload }) => {
      state.loginData = { ...state.loginData, user_setting: payload }
    },
    updateGetPaid: (state, { payload }) => {
      state.getPaidData = payload
    },
    updateRating: (state, { payload }) => {
      state.userRating = payload
    },
    updateAllMembershipDetails: (state, { payload }) => {
      state.allMembershipDetails = payload
    },
    updateUserSubscriptionDetail: (state, { payload }) => {
      state.userSubscriptionDetail = payload
    },
    logout: (state) => {
      state.loginData = initLoginData
      state.userProfile = null
      state.isLogin = false
      state.isMerchant = false
      state.getPaidData = null
      state.userRating = {}
      state.userSubscriptionDetail = null
      state.userKDollar = null
      local.remove(KEYS.ACCESS_TOKEN)
    },
  },
})

// action creators
export const {
  updateLoginData,
  updateSetting,
  updateGetPaid,
  updateAllMembershipDetails,
  updateUserSubscriptionDetail,
  updateRating,
  logout,
} = userSlice.actions
// reducer
export default userSlice.reducer
