import useSWR from "swr"
import useSWRInfinite from "swr/infinite"
import customAxios from "../axios"

export async function getSellerRating(id) {
  const endPoint = `/api/users/seller/${id}/ratings`
  const res = await customAxios.get(endPoint)

  return res.data
}

export async function createSellerRating({ orderId, payload }) {
  const endPoint = `/api/orders/me/buy/${orderId}/ratings`
  const res = await customAxios.post(endPoint, payload)

  return res.data
}

export function useSellerRating(id) {
  const endPoint = `/api/users/seller/${id}/ratings`
  const { data, error } = useSWR(id ? endPoint : null, customAxios.get)
  const ratings = data?.data

  return [ratings, error]
}

export function usePaginatedSellerRating({ params, id }) {
  const baseEndPoint = `/api/users/seller/${id}/ratings`
  const getKey = (pageIndex) => {
    if (!id) {
      return null
    }
    const endPoint = customAxios.getUri({
      url: baseEndPoint,
      params: {
        ...params,
        offset: pageIndex * params.hits,
      },
    })
    return endPoint
  }
  const {
    data: res,
    mutate,
    size,
    setSize,
  } = useSWRInfinite(getKey, customAxios.get, { revalidateOnMount: true })

  return { res: res || [], mutate, size, setSize }
}
