import Head from "next/head"

const TRACKING_ID = process.env.NEXT_PUBLIC_BAIDU_TRACKING_ID
const BaiduTracking = () => {
  if (!TRACKING_ID) return <></>
  return (
    <Head>
      <script
        id="baidu-tracking"
        dangerouslySetInnerHTML={{
          __html: `
                  var _hmt = _hmt || [];
                  (function() {
                  var hm = document.createElement("script");
                  hm.src = "https://hm.baidu.com/hm.js?${TRACKING_ID}";
                  var s = document.getElementsByTagName("script")[0]; 
                  s.parentNode.insertBefore(hm, s);
                  })();`,
        }}
      />
    </Head>
  )
}

export default BaiduTracking
