const SuccessToast = ({ message }) => {
  return (
    <div className="flex items-center font-bold text-sm text-black-900">
      <div className="ml-1.5">
        <img src="/icon/ok.svg" alt="" height={15} width={15} />
      </div>
      <p className="ml-3">{message}</p>
    </div>
  )
}

export default SuccessToast
