import { Transition } from "@headlessui/react"
import { LazyLoadImage } from "@/components/common/Image"
import { useDispatch, useSelector } from "react-redux"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { updateLoading } from "@/store/content"
import { SUBMIT_WATCH_PATHS } from "@/hooks/useBeforeUnload"

const Loading = () => {
  const router = useRouter()
  const loading = useSelector((state) => state.content.loading)
  const dispatch = useDispatch()

  useEffect(() => {
    function addLoading() {
      if (SUBMIT_WATCH_PATHS.indexOf(router.pathname) !== -1) return
      dispatch(updateLoading({ loading: true }))
    }

    function removeLoading() {
      dispatch(updateLoading({ loading: false }))
    }

    router.events.on("routeChangeStart", addLoading)
    router.events.on("routeChangeComplete", removeLoading)
    router.events.on("routeChangeError", removeLoading)
    return () => {
      router.events.off("routeChangeStart", addLoading)
      router.events.off("routeChangeComplete", removeLoading)
      router.events.off("routeChangeError", removeLoading)
    }
  }, [router])

  return (
    <Transition
      show={loading}
      enter="transition ease-in-out duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-out-in duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`fixed flex justify-center items-center z-50 top-0 left-0 w-screen h-screen grow bg-white opacity-80`}
      >
        <LazyLoadImage
          src="/k8-loading.gif"
          width="185"
          height="60"
          alt="Loading"
          className="opacity-1 inline-block relative -ml-[25px] -mt-[25px]"
        />
      </div>
    </Transition>
  )
}

export default Loading
