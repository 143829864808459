import {
  WATCH_STEP_ONE,
  WATCH_STEP_TWO,
  WATCH_STEP_THREE,
  WATCH_STEP_FOUR,
  WATCH_STEP_FIVE,
  WATCH_STEP_SIX,
  WATCH_STEP_PREVIEW,
} from "./constants"

export const initialState = {
  currentStep: WATCH_STEP_ONE,
  visitedSteps: [],
}

export const stepTransitions = {
  [WATCH_STEP_ONE]: {
    next: WATCH_STEP_TWO,
    previous: WATCH_STEP_ONE,
  },
  [WATCH_STEP_TWO]: {
    next: WATCH_STEP_THREE,
    previous: WATCH_STEP_ONE,
  },
  [WATCH_STEP_THREE]: {
    next: WATCH_STEP_FOUR,
    previous: WATCH_STEP_TWO,
  },
  [WATCH_STEP_FOUR]: {
    next: WATCH_STEP_FIVE,
    previous: WATCH_STEP_THREE,
  },
  [WATCH_STEP_FIVE]: {
    next: WATCH_STEP_SIX,
    previous: WATCH_STEP_FOUR,
  },
  [WATCH_STEP_SIX]: {
    next: WATCH_STEP_PREVIEW,
    previous: WATCH_STEP_FIVE,
  },
  [WATCH_STEP_PREVIEW]: {
    next: WATCH_STEP_PREVIEW,
    previous: WATCH_STEP_SIX,
  },
}

const FORWARD = "FORWARD"
const BACKWARD = "BACKWARD"
const JUMP = "JUMP"

export const stepForward = (step) => ({ type: FORWARD, payload: step })

export const stepBackward = (step) => ({ type: BACKWARD, payload: step })

export const stepJump = ({ current, target }) => ({
  type: JUMP,
  payload: { current, target },
})

export function stepReducer(state, action) {
  switch (action.type) {
    case FORWARD:
      return {
        ...state,
        currentStep: stepTransitions[action.payload].next,
        visitedSteps: state.visitedSteps.includes(action.payload)
          ? state.visitedSteps
          : [...state.visitedSteps, action.payload],
      }
    case BACKWARD:
      return {
        ...state,
        currentStep: stepTransitions[action.payload].previous,
        visitedSteps: state.visitedSteps.includes(action.payload)
          ? state.visitedSteps
          : [...state.visitedSteps, action.payload],
      }
    case JUMP:
      return {
        ...state,
        currentStep: action.payload.target,
        visitedSteps: state.visitedSteps.includes(action.payload.current)
          ? state.visitedSteps
          : [...state.visitedSteps, action.payload.current],
      }
    default: {
      return state
    }
  }
}
