import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google"
import axios from "axios"
import Icon, { IconImg } from "./Icon"
import Debug from "debug"
const debug = Debug("web:component:google")
const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID

const Google = function ({ onSuccess, onError }) {
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const response = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        }
      )
      onSuccess(response.data)
    },
    onFailure: onFailure,
  })
  function onFailure(res) {
    debug(res)
    if (res?.error == "idpiframe_initialization_failed") return // ignore google init error
    onError(res)
  }
  return (
    <Icon onClick={() => login()}>
      <IconImg src="/icon/google.svg" alt="google" />
    </Icon>
  )
}

export const GoogleOauthProvider = ({ children }) => (
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    {children}
  </GoogleOAuthProvider>
)

export default Google
