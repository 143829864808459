import { configureStore } from "@reduxjs/toolkit"
import userReducer from "./user"
import watchFormReducer from "./sellWatch"
import contentReducer from "./content"
import errorReducer from "./error"
import oAuthReducer from "./oauth"

let store = null

export default function createsStore(preloadedState) {
  if (store) return store
  store = configureStore({
    reducer: {
      user: userReducer,
      watchForm: watchFormReducer,
      content: contentReducer,
      error: errorReducer,
      oauth: oAuthReducer,
    },
    preloadedState,
  })
  return store
}
