import { useEffect, useState, useRef } from "react"
import { useRouter } from "next/router"

export const SUBMIT_WATCH_PATHS = [
  "/sell/resubmit",
  "/sell/watch",
  "/sell/modify",
]

export const useBeforeUnload = (initialState = false) => {
  const target = useRef()
  const router = useRouter()
  const [state, setState] = useState(initialState)

  const setShow = () => setState(true)
  const setHide = () => setState(false)
  const setTarget = (value) => (target.current = value)

  const handleRouteStayPage = () => {
    setTarget("")
    setHide()
  }
  const handleRouteLeavePage = () => {
    setHide()

    router.push(target.current)
  }

  useEffect(() => {
    const handleRouteChange = (url) => {
      // prevent leave page popup show again when change lang setting
      const pathname = url.replace("/zh-HK", "").replace("/zh-CN", "")
      if (SUBMIT_WATCH_PATHS.indexOf(pathname) !== -1) return
      if (!target.current) {
        target.current = url
        setShow()
        throw ""
      }
    }
    router.events.on("routeChangeStart", handleRouteChange)
    return () => router.events.off("routeChangeStart", handleRouteChange)
  }, [router.events])

  useEffect(() => {
    const confirmLeave = (e) => {
      e.preventDefault()
      if (target.current) return ""
      return (e.returnValue = "Do you really want to close?")
    }
    window.addEventListener("beforeunload", confirmLeave)
    return () => window.removeEventListener("beforeunload", confirmLeave)
  })

  return {
    state,
    target,
    setShow,
    setHide,
    setTarget,
    handleRouteStayPage,
    handleRouteLeavePage,
  }
}
