import { createSlice } from "@reduxjs/toolkit"

export const CREATE_ACCOUNT = "CREATE_ACCOUNT"
export const SOCIAL_CREATE_ACCOUNT = "SOCIAL_CREATE_ACCOUNT"
export const ENTER_CONFIRM_CODE = "ENTER_CONFIRM_CODE"
export const ENTER_REFERRAL = "ENTER_REFERRAL"

const initialState = {
  user: {},
  social: {},
  skipVerifyEmail: false,
  step: CREATE_ACCOUNT,
}

const OAuthSlice = createSlice({
  name: "oauth",
  initialState,
  reducers: {
    createAccount: (state, { payload }) => {
      state.user = payload.data
      state.step = ENTER_CONFIRM_CODE
    },
    createSocialAccount: (state, { payload }) => {
      state.user = {}
      state.social = payload.data
      state.skipVerifyEmail = Boolean(payload.skipVerifyEmail)
      state.step = SOCIAL_CREATE_ACCOUNT
    },
    setCreateAccount: (state) => {
      state.step = CREATE_ACCOUNT
    },
    setEnterReferral: (state) => {
      state.step = ENTER_REFERRAL
    },
    setEnterConfirmationCode: (state, { payload }) => {
      state.step = ENTER_CONFIRM_CODE
      state.user = payload.user
    },
    resetOAuth: () => {
      return initialState
    },
  },
})

export const {
  createAccount,
  createSocialAccount,
  enterReferral,
  setCreateAccount,
  setEnterReferral,
  setEnterConfirmationCode,
  resetOAuth,
} = OAuthSlice.actions

export default OAuthSlice.reducer
