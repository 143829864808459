import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"

export const RAW_LOCALE = {
  EN: "en",
  ZH_HK: "zh-HK",
  ZH_CN: "zh-CN",
}

const localMapper = {
  en: "EN",
  "zh-HK": "TC",
  "zh-CN": "SC",
}

const DEFAULT_LANG = "en"

function multilang(obj, key, lang) {
  if (!obj) return
  return (
    obj[`${key}${localMapper[lang]}`] ||
    obj[`${key}${localMapper[DEFAULT_LANG]}`]
  )
}

function multilangJson(
  json,
  value,
  lang,
  valueKey = "value",
  displayKey = "display"
) {
  for (const opt of json) {
    if (opt[valueKey] === value) {
      const key = `${displayKey}${localMapper[lang]}`
      return opt[key]
    }
  }
  return null
}

const useMultilang = () => {
  const { locale } = useRouter()
  const { t } = useTranslation()

  return {
    multilang: (obj, key) => multilang(obj, key, locale),
    filterOption: (obj, key) =>
      ["EN", "TC", "SC"].map((k) => obj[`${key}${k}`]).filter((val) => val),
    t: (val, option) => t(val, option),
    multilangJson: (json, value, ...rest) =>
      multilangJson(json, value, locale, ...rest),
    locale,
  }
}

export { useMultilang, multilang }
